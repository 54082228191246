import React, { useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';

const ThemeToggleButton = ({ theme, toggleTheme }) => {
  const [props, set] = useSpring(() => ({
    sun: theme === 'dark' ? [-180, 0] : [0, 0],
    moon: theme === 'dark' ? [0, 0] : [-180, 0],
  }));

  useEffect(() => {
    set({
      sun: theme === 'dark' ? [-180, 0] : [0, 0],
      moon: theme === 'dark' ? [0, 0] : [-180, 0],
    });
  }, [theme, set]);

  return (
    <svg
      id="theme-toggle"
      version="1.2"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 180 180"
      xmlSpace="preserve"
      onClick={toggleTheme}
    >
      <a.g
        style={{
          transform: props.moon.interpolate(
            (x, y) => `translate(${x}px, ${y}px)`
          ),
        }}
      >
        <path
          display="inline"
          fill="#FFFF80"
          stroke="#FFFF00"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
         M90.5,93.7C79.2,72.9,82.4,47,89.7,26.8c-1.3,0.6-2.6,1.3-3.9,2c-31,16.9-43.6,53.6-28.2,81.9c15.5,28.3,53.1,37.5,84.1,20.5
         c0.7-0.4,1.4-0.8,2.1-1.2C123.9,124.7,101.4,113.7,90.5,93.7z"
        />

        <text
          transform="matrix(1 0 0 1 110.2471 94.6001)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="12px"
        >
          *
        </text>

        <text
          transform="matrix(1 0 0 1 35.2461 62.458)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="26.1674px"
        >
          *
        </text>

        <text
          transform="matrix(0.9394 0.343 -0.343 0.9394 122.5199 90.9772)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="45.0511px"
        >
          *
        </text>

        <text
          transform="matrix(0.5669 0.8238 -0.8238 0.5669 53.0929 145.1581)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="15.5926px"
        >
          *
        </text>

        <text
          transform="matrix(0.6683 0.5673 -0.6471 0.7624 23.9864 96.8022)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="22.58px"
        >
          *
        </text>

        <text
          transform="matrix(1 0 0 1 102.2461 65.458)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="26.1674px"
        >
          *
        </text>

        <text
          transform="matrix(1 0 0 1 26.2461 147.6289)"
          display="inline"
          fill="#E6E6E6"
          fontFamily="'MyriadPro-Regular'"
          fontSize="45.4864px"
        >
          *
        </text>
      </a.g>

      <a.g
        style={{
          transform: props.sun.interpolate(
            (x, y) => `translate(${x}px, ${y}px)`
          ),
        }}
      >
        <g>
          <path
            fill="#FBB03B"
            d="M89.8,114.3c-3.2,0-6.3-0.6-9.2-1.8c-12.4-5.1-18.3-19.3-13.2-31.6c3.8-9.1,12.6-15,22.4-15
      c3.2,0,6.3,0.6,9.2,1.8c6,2.5,10.7,7.1,13.1,13.1c2.5,6,2.5,12.6,0,18.5C108.5,108.4,99.7,114.3,89.8,114.3z"
          />
          <path
            fill="#F15A24"
            d="M89.8,62.8l0,6c0,0,0,0,0,0c2.8,0,5.5,0.5,8.1,1.6c5.2,2.2,9.3,6.2,11.5,11.5c2.2,5.2,2.2,11,0,16.2
      c-3.3,8-11,13.2-19.6,13.2c-2.8,0-5.5-0.5-8.1-1.6c-5.2-2.2-9.3-6.2-11.5-11.5C68,93,68,87.2,70.2,82c3.3-8,11-13.2,19.6-13.2
      L89.8,62.8 M89.8,62.8c-10.7,0-20.9,6.4-25.2,16.9c-5.7,13.9,0.9,29.8,14.8,35.5c3.4,1.4,6.9,2.1,10.3,2.1
      c10.7,0,20.9-6.4,25.2-16.9c5.7-13.9-0.9-29.8-14.8-35.5C96.8,63.5,93.3,62.8,89.8,62.8L89.8,62.8z"
          />
        </g>
        <g>
          <path
            fill="#FBB03B"
            d="M36.6,114.3c-0.8,0-1.6-0.5-1.9-1.2c-0.2-0.5-0.2-1,0-1.5s0.6-0.9,1.1-1.1l17.8-7.4
      c0.2-0.1,0.5-0.2,0.8-0.2c0.8,0,1.6,0.5,1.9,1.2c0.4,1-0.1,2.2-1.1,2.6l-17.8,7.4C37.1,114.2,36.8,114.3,36.6,114.3z"
          />
          <path
            fill="#FBB03B"
            d="M54.4,104.3c0.2,0,0.4,0.1,0.5,0.3c0.1,0.3,0,0.6-0.3,0.7l-17.8,7.4c-0.1,0-0.1,0-0.2,0
      c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.3,0-0.6,0.3-0.7l17.8-7.4C54.3,104.3,54.3,104.3,54.4,104.3 M54.4,101.3c-0.4,0-0.9,0.1-1.3,0.3
      L35.2,109c-1.8,0.7-2.6,2.8-1.9,4.6v0c0.6,1.3,1.9,2.2,3.3,2.2c0.4,0,0.9-0.1,1.3-0.3l17.8-7.4c1.8-0.7,2.6-2.8,1.9-4.6v0
      C57.1,102.1,55.8,101.3,54.4,101.3L54.4,101.3z"
          />
        </g>
        <g>
          <path
            fill="#FBB03B"
            d="M112,145.3c-0.8,0-1.6-0.5-1.9-1.2l-7.4-17.8c-0.2-0.5-0.2-1,0-1.5c0.2-0.5,0.6-0.9,1.1-1.1
      c0.2-0.1,0.5-0.2,0.8-0.2c0.8,0,1.6,0.5,1.9,1.2l7.4,17.8c0.2,0.5,0.2,1,0,1.5c-0.2,0.5-0.6,0.9-1.1,1.1
      C112.6,145.3,112.3,145.3,112,145.3z"
          />
          <path
            fill="#FBB03B"
            d="M104.6,124.9c0.2,0,0.4,0.1,0.5,0.3l7.4,17.8c0.1,0.2,0,0.3,0,0.4c0,0.1-0.1,0.2-0.3,0.3
      c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4-0.1-0.5-0.3l-7.4-17.8c-0.1-0.2,0-0.3,0-0.4c0-0.1,0.1-0.2,0.3-0.3
      C104.4,125,104.5,124.9,104.6,124.9 M104.6,121.9c-0.4,0-0.9,0.1-1.3,0.3h0c-1.8,0.7-2.6,2.8-1.9,4.6l7.4,17.8
      c0.6,1.3,1.9,2.2,3.3,2.2c0.4,0,0.9-0.1,1.3-0.3h0c1.8-0.7,2.6-2.8,1.9-4.6l-7.4-17.8C107.3,122.8,105.9,121.9,104.6,121.9
      L104.6,121.9z"
          />
        </g>
        <g>
          <path
            fill="#FBB03B"
            d="M125.2,77.3c-0.8,0-1.6-0.5-1.9-1.2c-0.4-1,0.1-2.2,1.1-2.6l17.8-7.4c0.2-0.1,0.5-0.2,0.8-0.2
      c0.8,0,1.6,0.5,1.9,1.2c0.4,1-0.1,2.2-1.1,2.6L126,77.1C125.8,77.2,125.5,77.3,125.2,77.3z"
          />
          <path
            fill="#FBB03B"
            d="M143.1,67.3c0.2,0,0.4,0.1,0.5,0.3c0.1,0.3,0,0.6-0.3,0.7l-17.8,7.4c-0.1,0-0.1,0-0.2,0
      c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.3,0-0.6,0.3-0.7l17.8-7.4C142.9,67.3,143,67.3,143.1,67.3 M143.1,64.3c-0.4,0-0.9,0.1-1.3,0.3
      L123.9,72c-1.8,0.7-2.6,2.8-1.9,4.6l0,0c0.6,1.3,1.9,2.2,3.3,2.2c0.4,0,0.9-0.1,1.3-0.3l17.8-7.4c1.8-0.7,2.6-2.8,1.9-4.6v0
      C145.7,65.1,144.4,64.3,143.1,64.3L143.1,64.3z"
          />
        </g>
        <g>
          <path
            fill="#FBB03B"
            d="M75.4,57.6c-0.8,0-1.6-0.5-1.9-1.2l-7.4-17.8c-0.4-1,0.1-2.2,1.1-2.6c0.2-0.1,0.5-0.2,0.8-0.2
      c0.8,0,1.6,0.5,1.9,1.2l7.4,17.8c0.2,0.5,0.2,1,0,1.5s-0.6,0.9-1.1,1.1C76,57.5,75.7,57.6,75.4,57.6z"
          />
          <path
            fill="#FBB03B"
            d="M68,37.2c0.2,0,0.4,0.1,0.5,0.3l7.4,17.8c0.1,0.2,0,0.3,0,0.4c0,0.1-0.1,0.2-0.3,0.3c-0.1,0-0.1,0-0.2,0
      c-0.2,0-0.4-0.1-0.5-0.3l-7.4-17.8c-0.1-0.3,0-0.6,0.3-0.7C67.8,37.2,67.9,37.2,68,37.2 M68,34.2c-0.4,0-0.9,0.1-1.3,0.3h0
      c-1.8,0.7-2.6,2.8-1.9,4.6l7.4,17.8c0.6,1.3,1.9,2.2,3.3,2.2c0.4,0,0.9-0.1,1.3-0.3h0c1.8-0.7,2.6-2.8,1.9-4.6l-7.4-17.8
      C70.7,35,69.3,34.2,68,34.2L68,34.2z"
          />
        </g>
        <g>
          <path
            fill="#F15A24"
            d="M105.2,54.6c-0.3,0-0.5-0.1-0.8-0.1c-1-0.4-1.5-1.6-1.1-2.6l7.3-17.9c0.3-0.7,1.1-1.2,1.9-1.2
      c0.3,0,0.5,0.1,0.8,0.1c1,0.4,1.5,1.6,1.1,2.6l-7.3,17.9C106.7,54.2,106,54.6,105.2,54.6z M115.9,28.6c-0.3,0-0.5-0.1-0.8-0.2
      c-1-0.4-1.5-1.6-1.1-2.6c0.3-0.8,1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.2c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.2,1,0,1.5
      C117.4,28.2,116.7,28.6,115.9,28.6L115.9,28.6z"
          />
          <path
            fill="#F15A24"
            d="M115.9,26.1c0.1,0,0.1,0,0.2,0c0.2,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.2,0,0.4c-0.1,0.2-0.3,0.3-0.5,0.3
      c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.3-0.7C115.5,26.2,115.7,26.1,115.9,26.1 M112.6,34.2c0.1,0,0.1,0,0.2,0
      c0.3,0.1,0.4,0.4,0.3,0.7l-7.3,17.9c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2,0-0.4
      l7.3-17.9C112.2,34.3,112.4,34.2,112.6,34.2 M115.9,23.1c-1.4,0-2.7,0.8-3.3,2.2c-0.7,1.8,0.1,3.8,1.9,4.6
      c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2c0.7-1.8-0.1-3.8-1.9-4.6C116.8,23.2,116.3,23.1,115.9,23.1L115.9,23.1z
       M112.6,31.2c-1.4,0-2.7,0.8-3.3,2.2l-7.3,17.9c-0.7,1.8,0.1,3.9,1.9,4.6h0c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2
      l7.3-17.9c0.7-1.8-0.1-3.9-1.9-4.6l0,0C113.4,31.3,113,31.2,112.6,31.2L112.6,31.2z"
          />
        </g>
        <g>
          <path
            fill="#F15A24"
            d="M63.7,155.5c-0.3,0-0.5-0.1-0.8-0.2c-1-0.4-1.5-1.6-1.1-2.6c0.3-0.8,1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.2
      c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.2,1,0,1.5C65.3,155,64.5,155.5,63.7,155.5C63.7,155.5,63.7,155.5,63.7,155.5z M67.1,147.3
      c-0.3,0-0.5-0.1-0.8-0.1c-1-0.4-1.5-1.6-1.1-2.6l7.3-17.9c0.3-0.7,1.1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.1c0.5,0.2,0.9,0.6,1.1,1.1
      c0.2,0.5,0.2,1,0,1.5l-7.3,17.9C68.6,146.8,67.9,147.3,67.1,147.3z"
          />
          <path
            fill="#F15A24"
            d="M74.4,126.9c0.1,0,0.1,0,0.2,0c0.3,0.1,0.4,0.4,0.3,0.7l-7.3,17.9c-0.1,0.2-0.3,0.3-0.5,0.3
      c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2,0-0.4l7.3-17.9C74,127,74.3,126.9,74.4,126.9 M63.7,152.9
      c0.1,0,0.1,0,0.2,0c0.3,0.1,0.4,0.4,0.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.2-0.3-0.3
      c0-0.1-0.1-0.2,0-0.4C63.4,153,63.6,152.9,63.7,152.9 M74.4,123.9c-1.4,0-2.7,0.8-3.3,2.2L63.8,144c-0.7,1.8,0.1,3.9,1.9,4.6
      c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2l7.3-17.9c0.7-1.8-0.1-3.9-1.9-4.6h0C75.3,124,74.9,123.9,74.4,123.9L74.4,123.9z
       M63.7,149.9c-1.4,0-2.7,0.8-3.3,2.2c-0.7,1.8,0.1,3.8,1.9,4.6c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2
      c0.7-1.8-0.1-3.8-1.9-4.6C64.6,150,64.2,149.9,63.7,149.9L63.7,149.9z"
          />
        </g>
        <g>
          <path
            fill="#F15A24"
            d="M52.4,76.7c-0.3,0-0.5-0.1-0.8-0.1l-17.9-7.3c-0.5-0.2-0.9-0.6-1.1-1.1c-0.2-0.5-0.2-1,0-1.5
      c0.3-0.8,1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.1l17.9,7.3c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.2,1,0,1.5C54,76.2,53.2,76.7,52.4,76.7
      z M26.4,66c-0.3,0-0.5-0.1-0.8-0.2c-0.5-0.2-0.9-0.6-1.1-1.1s-0.2-1,0-1.5c0.3-0.8,1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.2
      c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.2,1,0,1.5C28,65.5,27.2,66,26.4,66z"
          />
          <path
            fill="#F15A24"
            d="M26.4,63.4c0.1,0,0.1,0,0.2,0c0.2,0.1,0.2,0.2,0.3,0.3s0.1,0.2,0,0.4c-0.1,0.2-0.3,0.3-0.5,0.3
      c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.2-0.2-0.3-0.3s-0.1-0.2,0-0.4C26,63.5,26.2,63.4,26.4,63.4 M34.5,66.8c0.1,0,0.1,0,0.2,0l17.9,7.3
      c0.3,0.1,0.4,0.4,0.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.1,0-0.2,0l-17.9-7.3c-0.2-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2,0-0.4
      C34.1,66.9,34.4,66.8,34.5,66.8 M26.4,60.4c-1.4,0-2.7,0.8-3.3,2.2c-0.7,1.8,0.1,3.8,1.9,4.6c0.4,0.2,0.9,0.3,1.3,0.3
      c1.4,0,2.7-0.8,3.3-2.2c0.7-1.8-0.1-3.8-1.9-4.6C27.3,60.5,26.9,60.4,26.4,60.4L26.4,60.4z M34.5,63.8c-1.4,0-2.7,0.8-3.3,2.2h0
      c-0.7,1.8,0.1,3.9,1.9,4.6l17.9,7.3c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2v0c0.7-1.8-0.1-3.9-1.9-4.6l-17.9-7.3
      C35.4,63.9,35,63.8,34.5,63.8L34.5,63.8z"
          />
        </g>
        <g>
          <path
            fill="#F15A24"
            d="M153.2,118.1c-0.3,0-0.5-0.1-0.8-0.2c-0.5-0.2-0.9-0.6-1.1-1.1c-0.2-0.5-0.2-1,0-1.5
      c0.3-0.8,1-1.2,1.9-1.2c0.3,0,0.5,0.1,0.8,0.2c1,0.4,1.5,1.6,1.1,2.6C154.8,117.6,154,118.1,153.2,118.1L153.2,118.1z
       M145.1,114.8c-0.3,0-0.5-0.1-0.8-0.2l-17.9-7.3c-0.5-0.2-0.9-0.6-1.1-1.1c-0.2-0.5-0.2-1,0-1.5c0.3-0.8,1-1.2,1.9-1.2
      c0.3,0,0.5,0.1,0.8,0.2l17.9,7.3c0.5,0.2,0.9,0.6,1.1,1.1c0.2,0.5,0.2,1,0,1.5C146.6,114.3,145.9,114.8,145.1,114.8z"
          />
          <path
            fill="#F15A24"
            d="M127.2,104.9c0.1,0,0.1,0,0.2,0l17.9,7.3c0.3,0.1,0.4,0.4,0.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
      c-0.1,0-0.1,0-0.2,0l-17.9-7.3c-0.2-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2,0-0.4C126.8,105,127,104.9,127.2,104.9 M153.2,115.6
      c0.1,0,0.1,0,0.2,0c0.2,0.1,0.2,0.2,0.3,0.3c0,0.1,0.1,0.2,0,0.4c-0.1,0.2-0.3,0.3-0.5,0.3c-0.1,0-0.1,0-0.2,0
      c-0.2-0.1-0.2-0.2-0.3-0.3c0-0.1-0.1-0.2,0-0.4C152.8,115.7,153.1,115.6,153.2,115.6 M127.2,101.9c-1.4,0-2.7,0.8-3.3,2.2v0
      c-0.7,1.8,0.1,3.9,1.9,4.6l17.9,7.3c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2v0c0.7-1.8-0.1-3.9-1.9-4.6l-17.9-7.3
      C128.1,102,127.7,101.9,127.2,101.9L127.2,101.9z M153.2,112.6c-1.4,0-2.7,0.8-3.3,2.2c-0.7,1.8,0.1,3.8,1.9,4.6
      c0.4,0.2,0.9,0.3,1.3,0.3c1.4,0,2.7-0.8,3.3-2.2c0.7-1.8-0.1-3.8-1.9-4.6C154.1,112.7,153.7,112.6,153.2,112.6L153.2,112.6z"
          />
        </g>
      </a.g>
    </svg>
  );
};

ThemeToggleButton.defaultProps = {
  theme: 'dark',
  toggleTheme: () => {},
};

export default ThemeToggleButton;
