import React, { useEffect, useState } from 'react';
import { getTheme, setTheme } from '../../helper/helper';
import Seo from '../seo';
import Nav from '../nav';
import Footer from '../footer';

const Layout = props => {
  const [mode, setMode] = useState(getTheme());

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.document.body.style.transition = 'background-color 0.3s';
      window.document.body.classList.remove('light', 'dark');
      window.document.body.classList.add(mode);
    }
    setTheme(mode);
  }, [mode]);

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { theme: mode, setTheme: setMode, ...props })
  );
  return (
    <>
      <Seo theme={mode} {...props} />
      {props.data ? <Nav theme={mode} setTheme={setMode} {...props} /> : null}
      <div
        className={`${
          props.data && props.data.mdx
            ? 'container md-mode'
            : props.data && props.data.allMdx
            ? 'container'
            : 'container-fluid'
        } main-content`}
      >
        {childrenWithProps}
      </div>
      {props.data ? <Footer /> : null}
    </>
  );
};

export default Layout;

Layout.defaultProps = {};
