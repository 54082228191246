import React, { useState } from 'react';
import ThemeToggleButton from '../themeToggleButton';
import { Link } from 'gatsby';
import { useScrollPosition } from '../../helper/helper';

const Nav = props => {
  const { theme, setTheme } = props;

  console.log({ path: props.path });
  const [show, setShow] = useState(false);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y < prevPos.y && currPos.y >= 300;
      if (isShow !== show) setShow(isShow);
    },
    [show],
    undefined,
    true
  );

  return (
    <div className={`container nav-panel${show ? ` outline ${theme}` : ''} `}>
      <div className="row">
        <div className="col">
          <div className="pt-2">
            <h5>
              <ThemeToggleButton
                theme={theme}
                toggleTheme={() => {
                  setTheme(theme === 'dark' ? 'light' : 'dark');
                }}
              />
              <span className="float-right mt-1 pt-1 nav-bar">
                {/* Disable the rendering of the nav as ssr will churn out all the available path */}
                {typeof window !== `undefined` ? (
                  <>
                    {props.path !== '/posts/' && props.path !== '/' ? (
                      <Link to="/posts">Posts</Link>
                    ) : null}
                    {props.path !== '/posts/' && props.path !== '/'
                      ? ' | '
                      : null}
                    {/* {
                        props.path !== '/obsessions/' ? <Link to="/obsessions/">Obsessions</Link> : null
                      }
                      {
                        props.path !== '/' && props.path !== '/obsessions' ? ' | ' : null
                      } */}
                    {props.path !== '/' ? <Link to="/">Me</Link> : null}
                  </>
                ) : null}
              </span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
