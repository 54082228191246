import { useRef } from 'react';

import { useLayoutEffect, useEffect } from 'react';

export const randomPick = arr => {
  if (arr.length === 0) {
    return 0;
  }
  const random = Math.floor(Math.random() * arr.length);
  return arr[random];
};

export const getTheme = () => {
  try {
    return window.localStorage.getItem('theme')
      ? window.localStorage.getItem('theme')
      : 'dark';
  } catch (e) {
    return 'dark';
  }
};

export const setTheme = theme => {
  try {
    window.localStorage.setItem('theme', theme);
    return true;
  } catch (e) {
    return false;
  }
};

const getScrollPosition = ({ element, useWindow }) => {
  if (typeof window === `undefined`) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
};

export const useScrollPosition = (effect, deps, element, useWindow, wait) => {
  const isBrowser = typeof window !== `undefined`;
  const useIsomorphicLayoutEffect = isBrowser ? useLayoutEffect : useEffect;
  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return;
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
};

useScrollPosition.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
};
