/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Seo = props => {
  const { lang, meta, theme, data } = props;
  let metaDescription, title, author, url;
  if (data) {
    const { site } = data;
    url = site.siteMetadata.url;
    metaDescription = data.mdx
      ? data.mdx.frontmatter.description
      : props.description
      ? props.description
      : site.siteMetadata.description;
    title = data.mdx
      ? data.mdx.frontmatter.title
      : props.title
      ? props.title
      : site.siteMetadata.title;
    author = site.siteMetadata.author;
  } else {
    metaDescription = 'You arrived at an invalid page';
    title = 'Not Found';
    author = 'Peng Chong';
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${title} | Peng Chong`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: 'theme-color',
          content: theme === 'dark' ? '#131722' : '#fcfcfc',
        },
      ].concat(meta)}
    >
      <link
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossorigin="anonymous"
      ></link>
    </Helmet>
  );
};

Seo.defaultProps = {
  description: `Site about me!!`,
  lang: `en`,
  meta: [],
  theme: `dark`,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
